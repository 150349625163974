import React, { useState, useEffect } from 'react';
import CorrBase from './CorrBase/CorrBase';
import PerfBase from './PerfBase/PerfBase';
import { API } from './MyApi';
import { styled } from '@mui/system';
import { Grid, TextField, Box, ThemeProvider } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { Button } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Link } from 'react-router-dom';
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import Checkbox from '@mui/material/Checkbox';

const FormContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    width: '50%', // Adjust the width as needed
    margin: 'auto',
});

const Form = styled('form')({
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
});

const Home = (props) => {
    const [redirectlink, setRedirectlink] = useState("/");
    const [chillerAge, setChillerAge] = useState('');
    const [country, setCountry] = useState('KSA');
    const [siteLocation, setSiteLocation] = useState('Riyadh');
    const [productFamilies, setProductFamilies] = useState('30XA');
    const [productFamiliesData, setProductFamiliesData] = useState(['30XA']);
    const [productsize, setProductsize] = useState('-');
    const [productsizedata, setProductsizedata] = useState(['-']);
    const [baseType, setBaseType] = useState('corrbase');
    const [modelNumber, setModelNumber] = useState('');
    const [chillerSerialNum, setChillerSerialNum] = useState('');
    const [siteName, setSiteName] = useState('');
    const [customerName, setCustomerName] = useState('');
    const [open, setOpen] = React.useState(false);
    const [chillererror, setChillererror] = React.useState(false);
    const [evapChecked, setEvapChecked] = React.useState(false);

    // PERF BASE
    const [electricPrice, setElectricPrice] = useState('');
    const [buildingType, setBuildingType] = useState('Hospitals');
    const [manfbuildingType, setManfbuildingType] = useState('Commercial (Office / Shopping Mall)');
    const [AnnualPerfDgrade, setAnnualPerfDgrade] = useState('1.00');
    const [buildingloadperc, setBuildingloadperc] = useState('80.0');
    const [designedCapacity, setDesignedCapacity] = useState('');
    const [designedCOP, setDesignedCOP] = useState('');
    const [currency, setCurrency] = useState('SAR');
    const [manf_source, setmanf_source] = useState("EMEA");
    const [power_supply, setPower_supply] = useState("50Hz");
    const [investment, setInvestment] = useState('');
    const [fullChillerCode, setFullChillerCode] = useState('');
    const [eleccosterror, setEleccosterror] = React.useState(false);
    const [investerror, setInvesterror] = React.useState(false);
    const [chillercaperror, setChillercaperror] = React.useState(false);
    const [evap_tdb_min_data, setEvap_tdb_min_data] = React.useState(23.0);
    const [evap_rh_max_data, setEvap_rh_max_data] = React.useState(80.0);
    const [evap_cond_pump_power, setEvap_cond_pump_power] = React.useState(0);
    const [evap_cond_fan_power, setEvap_cond_fan_power] = React.useState(0);
    const [evap_cond_operating_months, setEvap_cond_operating_months] =
        React.useState(['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']);



    // CORR BASE
    const [distanceToPollution, setDistanceToPollution] = useState('');
    const [distanceToCoast, setDistanceToCoast] = useState(''); // Default to kms
    const [annualAveragedT, setAnnualAveragedT] = useState('');
    const [annualAveragedRH, setAnnualAveragedRH] = useState('');
    const [evaporativeCooling, setEvaporativeCooling] = useState(false);

    // const [corrosionData, setCorrosionData] = React.useState({});

    // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-==-=-=-=   CONSOLE LOG   -=-=-=-=-=-=-=-=-=-=-=-=-=-==-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-


    // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-==-=-=-=   APIS START  -=-=-=-=-=-=-=-=-=-=-=-=-=-==-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-

    useEffect(() => {
        getFamily();
    },[]);
     
    // useEffect(() => {
    //     getValuesWithSiteLocation();
    // }, [siteLocation]);

    useEffect(() => {
        getSizeWithFamily();
    }, [productFamilies, manf_source, power_supply]);

    // useEffect(() => {
    //     generate_surrogate_file();
    // }, [siteLocation, buildingType, buildingloadperc, manfbuildingType]);

    props.setCorrosionData(null);
    props.setBuidingInfoTable(null);
    props.setBuidingInfoTableHR(null);
    props.setBuidingInfoTableWeigh(null);
    props.setBuidingInfoTableTemp(null);

    const getValuesWithSiteLocation = async () => {
        console.log('siteLocation has changed:', siteLocation);
        setOpen(true);
        const param = {
            "location": siteLocation
        };
        if (siteLocation.length > 2) {
            const trh_data = await API.getData("/get_trh", param);
            console.log("T/RH Info", trh_data)
            if (trh_data) {
                setDistanceToCoast(trh_data.data.dist_m);
                setAnnualAveragedT(trh_data.data.TempC);
                setAnnualAveragedRH(trh_data.data.RH);
            }
        }
        setOpen(false);
    };

    const getSizeWithFamily = async () => {
        console.log('Family has changed:', productFamilies);
        setOpen(true);
        const param = {
            "family": productFamilies,
            "manf_source":manf_source,
            "power_supply":power_supply
        };
        const size_data = await API.getData("/get_familysize", param);
        console.log("FamilySize Info", size_data)
        if (size_data) {
            setProductsizedata(size_data.productsizes);
        }
        setOpen(false);
    };

    const getFamily = async () => {
        console.log('Family is set:');
        setOpen(true);
        const response = await API.getData("/get_families", {});
        if (response) {
            setProductFamiliesData(response.families);
        }
        setOpen(false);
    };

    const generate_surrogate_file = () => {
        const param = {
            "location": siteLocation,
            "buildingType": buildingType,
            "manfbuildingType": manfbuildingType,
            "buildingloadperc": buildingloadperc,
            "chillerfamily": productFamilies,
            "productsize": productsize,
            "evap_cond": evapChecked,
            "manf_source":manf_source,
            "power_supply":power_supply,
            "evap_cond_tdb_min": evap_tdb_min_data,
            "evap_cond_rh_max": evap_rh_max_data,
            "evap_cond_pump_power": evap_cond_pump_power,
            "evap_cond_fan_power": evap_cond_fan_power,
            "evap_cond_operating_months": evap_cond_operating_months
        };

        console.log('Surrogate file API Triggered:', param);
        (async () => {
            try {
                const resp = await API.postData("/generate_surrogate_file", param);
            } catch (error) {
                console.error("Error generating surrogate file:", error);
            }
        })();

        console.log("API call initiated.");

    };


    useEffect(() => {
        if (chillerAge === '' || electricPrice === '' || designedCapacity === '' || investment === '') {
            setRedirectlink("/");
        } else {
            setRedirectlink("outputs");
        }
        console.log("redirectlink", redirectlink)
    }, [chillerAge, electricPrice, designedCapacity, investment]);

    const callBackendApi = (params) => {
        if (chillerAge == '' || electricPrice == '' || designedCapacity == '' || investment == '') {
            console.log("chillerAge", chillerAge, "electricPrice", electricPrice, "designedCapacity", designedCapacity, "investment", investment)
            if (chillerAge == '') {
                setChillererror(true);
            }
            if (electricPrice == '') {
                setEleccosterror(true);
            }
            if (designedCapacity == '') {
                setChillercaperror(true);
            }
            if (investment == '') {
                setInvesterror(true);
            }
        }
        else {
            if (params.includes("estimate_corrosion_init")) {
                generate_surrogate_file() //Call the surrogate file generation
                estimateCorrosionDataApi();
            }
            if (params.includes("estimate_corrosion")) {
                estimateCorrosionDataApi();
            }
        }
    };

    const estimateCorrosionDataApi = async () => {

        // setOpen(true);
        const param = {
            "chillerfamily": productFamilies,
            "distancecoast": distanceToCoast,
            "avg_oat": annualAveragedT,
            "avg_rh": annualAveragedRH,
            "chiller_age": chillerAge,
            "dist_pollution": distanceToPollution,
            "evap_cool": evaporativeCooling,
            "location": siteLocation,
            "country": country,
            "buildingType": buildingType,
            "degrade": AnnualPerfDgrade,
            "designedCapacity": designedCapacity,
            "investment": investment,
            "electricPrice": electricPrice,
            "manfbuildingType": manfbuildingType,
            "buildingloadperc": buildingloadperc,
            "currency": currency,
            "manf_source":manf_source,
            "power_supply":power_supply,
            "productsize": productsize,
            "modelNumber": modelNumber,
            "chillerSerialNum": chillerSerialNum,
            "siteName": siteName,
            "customerName": customerName,
            "evap_cond": evapChecked,
            "evap_cond_tdb_min": evap_tdb_min_data,
            "evap_cond_rh_max": evap_rh_max_data,
            "evap_cond_pump_power": evap_cond_pump_power,
            "evap_cond_fan_power": evap_cond_fan_power,
            "evap_cond_operating_months": evap_cond_operating_months
        };
        console.log("estimateCorrosionDataApi", param);
        const unit_info = await API.postData("/estimate_corrosion_data", param);
        console.log("final Result data", unit_info)
        if (unit_info) {
            props.setCorrosionData(unit_info.data.resultdata);
            // props.setBuidingInfoTable(unit_info.data.dataframes.Load);
            // props.setBuidingInfoTableHR(unit_info.data.dataframes.Hour);
        }

        if (unit_info.data.resultdata.surrogate_exist === true) {
            // props.setBuidingInfoTableWeigh(unit_info.data.dataframes);
            // props.setBuidingInfoTableTemp(unit_info.data.dataframes.downgraded_cop_values);
            // setOpen(false);
        }
        else if (unit_info.data.resultdata.no_sitedata === true) {
            console.log("No site data encountered")
            // setOpen(false);
        }
        else {
            // setOpen(false);
            console.log("corrosionDataResp", unit_info.data.resultdata);
            const intervalId = setInterval(async () => {
                const checkparam = {
                    "location": siteLocation,
                    "buildingType": buildingType,
                    "manfbuildingType": manfbuildingType,
                    "buildingloadperc": buildingloadperc,
                    "chillerfamily": productFamilies,
                    "productsize": productsize,
                    "evap_cond": evapChecked,
                    "manf_source":manf_source,
                    "power_supply":power_supply,
                    "evap_cond_tdb_min": evap_tdb_min_data,
                    "evap_cond_rh_max": evap_rh_max_data,
                    "evap_cond_pump_power": evap_cond_pump_power,
                    "evap_cond_fan_power": evap_cond_fan_power,
                    "evap_cond_operating_months": evap_cond_operating_months
                };
                const response = await API.postData("/check_surrogate_file", checkparam);
                console.log("check_response", response)
                if (response.surrogate_exist === true) {
                    console.log('Surrogate Data Fetch Completed..');
                    clearInterval(intervalId);
                    callBackendApi("estimate_corrosion");
                }
            }, 10000);
        }
    };



    // =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-==-=-=-=   APIS END   =-=-=-=-=-=-=-=-=-=-=-=-=-==-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=--
    const handleChange = (e) => {
        const { id, value } = e.target;
        switch (id) {
            case 'chillerAge':
                setChillerAge(value);
                setChillererror(false);
                break;
            case 'productFamilies':
                setProductFamilies(value);
                break;

            default:
                break;
        }


    };

    const handleFamilyChange = (event) => {
        setProductFamilies(event.target.value);
        setProductsize("-")
    };

    const SmallLabel = styled('span')({
        fontSize: '0.85rem', // Adjust the font size as needed
        fontWeight: 'bold', // You can adjust other styles here as well
    });


    const handleTypeChange = (value) => {
        setBaseType(value);
        console.log(baseType)
    };

    const countriesData = {
        KSA: ['Riyadh', 'Jeddah', 'Dammam'],
        UAE: ['Dubai', 'Abu Dhabi'],
        Kuwait: ['Kuwait City'],
        Qatar: ['Doha'],
        // UK:['London', 'Cambridge'],
        // Spain:['Tarragona', 'Derio','Sierra-de-cadix'],
        // Germany:['Tubingen', 'Sinshelm', 'Kuppenheim']

    };

    const ManfData = {
        EMEA: '50Hz',
        US: '60Hz',
    };


    const currencyData = {
        KSA: "SAR",
        UAE: "AED",
        Kuwait: "KWD",
        Qatar: "QAR",
        UK: "GBP",
        Spain: "EUR",
        Germany: "EUR"
    };

    const handleChangeCountry = (event) => {
        setCountry(event.target.value);
        setSiteLocation(countriesData[event.target.value][0]); // Reset siteLocation when country changes
        setCurrency(currencyData[event.target.value])
    };

    const handleChangeSize = (event) => {
        setProductsize(event.target.value);
    };

    const handleChangeSiteLocation = (event) => {
        setSiteLocation(event.target.value);
    };

    const handleClear = () => {
        setChillerAge('');
        setCountry('');
        setSiteLocation('');
        setProductFamilies('');
        setProductsize('-');
        setElectricPrice('');
        setBuildingType('');
        setAnnualPerfDgrade('1.00');
        setDesignedCapacity('');
        setDesignedCOP('');
        setCurrency('');
        setInvestment('');
        setFullChillerCode('');
        setDistanceToPollution('');
        setDistanceToCoast('');
        setAnnualAveragedT('');
        setAnnualAveragedRH('');
        setEvaporativeCooling(false);
        setModelNumber("");
        setChillerSerialNum("");
        setSiteName("");
        setCustomerName("");
    };

    const menuProps = {
        PaperProps: {
            style: {
                maxHeight: '50%', // This controls the maximum height of the dropdown menu
            },
        },
    };

    const handleCheckBoxChange = (event) => {
        setEvapChecked(event.target.checked);
        console.log("setEvapChecked", evapChecked)
    };

    return (
        <div style={{ paddingLeft: "15rem" }}>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress color="secondary" />
            </Backdrop>
            <Box
                my={10}
                alignItems="center"
                gap={1}
                p={0}
                sx={{ border: '1px solid grey', marginBottom: "1.2rem" }}
                style={{ marginRight: "5px", flex: "90%", boxShadow: "rgb(106 156 218 / 71%) 5px 4px 10px 0px", alignItems: "flex" }}
            >
                <div style={{ paddingTop: "6rem", padding: "1rem" }}>

                    <Grid style={{ paddingBottom: "0.8rem" }} container spacing={2}>
                        <Grid style={{ paddingTop: 8 }} item xs={12} sm={4}>
                            <FormControl fullWidth sx={{ m: 1, minWidth: 100 }}>
                                <InputLabel style={{ marginBottom: 10 }} id="demo-simple-select-helper-label"><SmallLabel>Product Family</SmallLabel></InputLabel>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={productFamilies}
                                    label="Product Familiy"
                                    onChange={handleFamilyChange}
                                >
                                    {productFamiliesData && productFamiliesData?.map((family) => (
                                        <MenuItem value={family}><SmallLabel>{family}</SmallLabel></MenuItem>))
                                    }
                    
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid style={{ paddingLeft: "3.5rem" }} item xs={12} sm={4}>
                            <TextField
                                id="chillerAge"
                                label={<SmallLabel>Chiller Age</SmallLabel>}
                                variant="outlined"
                                fullWidth
                                inputProps={{ min: 0 }}
                                type="number"
                                value={chillerAge}
                                onChange={handleChange}
                                error={chillererror}
                                helperText={chillererror ? 'Please enter the chiller age.' : ''}
                            />
                        </Grid>
                        <Grid style={{ paddingLeft: "3rem", paddingTop: 8 }} item xs={12} sm={4}>
                            <FormControl fullWidth sx={{ m: 1, minWidth: 100 }}>
                                <InputLabel style={{ marginBottom: 10 }} id="demo-simple-select-helper-label"><SmallLabel>Unit Size</SmallLabel></InputLabel>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={productsize}
                                    label="Size"
                                    onChange={handleChangeSize}
                                    MenuProps={menuProps}
                                >
                                    <MenuItem key={"-"} value={"-"}>
                                        <SmallLabel>{"-"}</SmallLabel>
                                    </MenuItem>
                                    {productsizedata.map((size) => (
                                        <MenuItem key={size} value={size}>
                                            <SmallLabel>{size}</SmallLabel>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid style={{ paddingBottom: "0.8rem" }} container spacing={2}>
                        <Grid style={{ paddingTop: 8 }} item xs={12} sm={4}>
                            <FormControl fullWidth sx={{ m: 1, minWidth: 100 }}>
                                <InputLabel style={{ marginBottom: 10 }} id="demo-simple-select-helper-label"><SmallLabel>Country</SmallLabel></InputLabel>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={country}
                                    label="Product Familiy"
                                    onChange={handleChangeCountry}
                                >
                                    {Object.keys(countriesData).map((countryKey) => (
                                        <MenuItem key={countryKey} value={countryKey}>
                                            <SmallLabel>{countryKey}</SmallLabel>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid style={{ paddingLeft: "3.5rem" }} item xs={12} sm={4}>
                            <TextField
                                id="siteLocation"
                                label={<SmallLabel>Site Location</SmallLabel>}
                                variant="outlined"
                                fullWidth

                                select
                                value={siteLocation}
                                onChange={handleChangeSiteLocation}
                                disabled={!country}
                            >
                                {country && countriesData[country].map((loc) => (
                                    <MenuItem key={loc} value={loc}>
                                        <SmallLabel>{loc}</SmallLabel>
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid style={{ paddingLeft: "3.5rem" }} item xs={12} sm={4}>
                            <div style={{ padding: "0.4rem", border: '1px solid #c0c0c0', borderRadius: "5px", marginRight: 8, display: 'flex', alignItems: 'center' }}>
                                <Checkbox
                                    id="checkbox"
                                    checked={evapChecked}
                                    variant="outlined"
                                    onChange={handleCheckBoxChange}
                                />
                                <label htmlFor="checkbox" >Evaporative Condenser</label>
                            </div>
                        </Grid>
                    </Grid>

                </div>

            </Box >

            <>
                <Box
                    my={5}
                    alignItems="center"
                    gap={1}
                    p={2}
                    sx={{ border: '1px solid grey', marginBottom: "1.2rem", marginTop: "1.2rem", boxShadow: "rgb(106 156 218 / 71%) 5px 4px 10px 0px" }}
                >
                    <PerfBase
                        electricPrice={electricPrice}
                        setElectricPrice={setElectricPrice}
                        buildingType={buildingType}
                        setBuildingType={setBuildingType}
                        manfbuildingType={manfbuildingType}
                        setManfbuildingType={setManfbuildingType}
                        AnnualPerfDgrade={AnnualPerfDgrade}
                        buildingloadperc={buildingloadperc}
                        setBuildingloadperc={setBuildingloadperc}
                        setAnnualPerfDgrade={setAnnualPerfDgrade}
                        designedCapacity={designedCapacity}
                        setDesignedCapacity={setDesignedCapacity}
                        designedCOP={designedCOP}
                        setDesignedCOP={setDesignedCOP}
                        currency={currency}
                        setCurrency={setCurrency}
                        investment={investment}
                        setInvestment={setInvestment}
                        fullChillerCode={fullChillerCode}
                        setFullChillerCode={setFullChillerCode}
                        eleccosterror={eleccosterror}
                        investerror={investerror}
                        chillercaperror={chillercaperror}
                        setEleccosterror={setEleccosterror}
                        setInvesterror={setInvesterror}
                        setChillercaperror={setChillercaperror}
                        modelNumber={modelNumber}
                        setModelNumber={setModelNumber}
                        chillerSerialNum={chillerSerialNum}
                        setChillerSerialNum={setChillerSerialNum}
                        siteName={siteName}
                        setSiteName={setSiteName}
                        customerName={customerName}
                        setCustomerName={setCustomerName}
                        evapChecked={evapChecked}
                        manf_source={manf_source}
                        setmanf_source={setmanf_source}
                        power_supply={power_supply}
                        setPower_supply={setPower_supply}
                        ManfData={ManfData}
                        evap_tdb_min_data={evap_tdb_min_data}
                        evap_rh_max_data={evap_rh_max_data}
                        setEvap_tdb_min_data={setEvap_tdb_min_data}
                        setEvap_rh_max_data={setEvap_rh_max_data}
                        evap_cond_pump_power={evap_cond_pump_power}
                        setEvap_cond_pump_power={setEvap_cond_pump_power}
                        evap_cond_fan_power={evap_cond_fan_power}
                        setEvap_cond_fan_power={setEvap_cond_fan_power}
                        evap_cond_operating_months={evap_cond_operating_months}
                        setEvap_cond_operating_months={setEvap_cond_operating_months}
                    ></PerfBase>
                </Box>
            </>


            <>
                {/* <Box
                    my={5}
                    alignItems="center"
                    gap={1}
                    p={2}
                    sx={{ border: '1px solid grey', marginBottom: "1.2rem", marginTop: "1.2rem", boxShadow: "rgb(106 156 218 / 71%) 5px 4px 10px 0px" }}
                >
                    <CorrBase
                        distanceToPollution={distanceToPollution}
                        distanceToCoast={distanceToCoast}
                        annualAveragedT={annualAveragedT}
                        annualAveragedRH={annualAveragedRH}
                        evaporativeCooling={evaporativeCooling}

                        setDistanceToPollution={setDistanceToPollution}
                        setDistanceToCoast={setDistanceToCoast}
                        setAnnualAveragedT={setAnnualAveragedT}
                        setAnnualAveragedRH={setAnnualAveragedRH}
                        setEvaporativeCooling={setEvaporativeCooling}
                    ></CorrBase>
                </Box> */}
            </>

            <Grid style={{ marginTop: "3rem", justifyContent: "center" }} container spacing={2}>
                <Grid style={{ paddingTop: 8 }} item xs={12} sm={2}>
                    <Button size='large' component={Link} to={redirectlink} onClick={() => callBackendApi(["estimate_corrosion_init"])} fullWidth variant="contained">Submit</Button>
                </Grid>
                <Grid style={{ paddingTop: 8 }} item xs={12} sm={2}>
                    <Button size='large' color='error' onClick={() => handleClear()} fullWidth variant="contained">Clear All</Button>
                </Grid>
            </Grid>

            <div style={{ marginBottom: "2rem" }}></div>
        </div >
    );
};

export default Home;

import React, { useState, useEffect } from 'react';
import Bar from './Components/Bar';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import CorrBase from './Components/CorrBase/CorrBase';
import PerfBase from './Components/PerfBase/PerfBase';
import OutputPage from './Components/OutputPage/OutputPage';
import Home from './Components/Home';
import Sidebar from './Components/Sidebar';
import Exit from './Components/Exit';
import { Box } from '@mui/system';
import { Container } from '@material-ui/core';
import { ReactAuthWrapper } from '@carrier/reactauthwrapper';
import appConfig from './config';

function App() {
  const [corrosionData, setCorrosionData] = React.useState(null);
  const [buidingInfoTable, setBuidingInfoTable] = useState('');
  const [buidingInfoTableHR, setBuidingInfoTableHR] = useState('');
  const [buidingInfoTableWeigh, setBuidingInfoTableWeigh] = useState('');
  const [buidingInfoTableTemp, setBuidingInfoTableTemp] = useState('');

  const [appAccess, setAppAccess] = useState(false);
  const callback = (permissions) => {
    console.log("permissions->", permissions);
    if (permissions.appAccess) {
      console.log("permissions->", permissions.appAccess);
      setAppAccess(true);
    }
  };

  return (
      <ReactAuthWrapper appConfig={appConfig.api} getPermissions={callback}>
        {appAccess && (
          // Application Code (User has access)
          <BrowserRouter>
            <Container maxWidth="xl">

              <Box sx={{ display: 'flex' }}>
                <Bar />
              </Box>
              <Sidebar />
              <Routes>
                <Route exact path='/' element={<Home
                  setCorrosionData={setCorrosionData}
                  corrosionData={corrosionData}
                  setBuidingInfoTable={setBuidingInfoTable}
                  setBuidingInfoTableHR={setBuidingInfoTableHR}
                  setBuidingInfoTableWeigh={setBuidingInfoTableWeigh}
                  setBuidingInfoTableTemp={setBuidingInfoTableTemp}

                />} />
                <Route exact path='/outputs' element={<OutputPage
                  corrosionData={corrosionData}
                  buidingInfoTable={buidingInfoTable}
                  buidingInfoTableHR={buidingInfoTableHR}
                  buidingInfoTableWeigh={buidingInfoTableWeigh}
                  buidingInfoTableTemp={buidingInfoTableTemp}
                />} />
                <Route exact path='/logout' element={<Exit />} />
              </Routes>
            </Container>
          </BrowserRouter >
        )}
      </ReactAuthWrapper >
  );
}

export default App;

import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import logo from "../pics/logo.png";
import { Link } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";

function Bar(props) {
  return (
    <AppBar
      position="fixed"
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      title={<img src={logo} />}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box
            component="img"
            sx={{
              height: 50,
            }}
            alt="Your logo."
            src={logo}
            px={2}
          />
          <Typography
            variant="h6"
            noWrap
            component="a"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            SO CLOUD
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {/* <Link
              to="/"
              style={{
                textDecoration: "none",
                color: "inherit",
                margin: "5px",
              }}
            >
              Home
            </Link> */}
            {/* <Link
              to="/plot"
              style={{
                textDecoration: "none",
                color: "inherit",
                margin: "5px",
              }}
            >
              Plot
            </Link> */}
            {/* <Link
              to="/offplot"
              style={{
                textDecoration: "none",
                color: "inherit",
                margin: "5px",
              }}
            >
              OfflinePlot
            </Link>
            <Link
              to="/retro"
              style={{
                textDecoration: "none",
                color: "inherit",
                margin: "5px",
                marginLeft:"10px"
              }}
            >
              L1FDD
            </Link> */}

            {/* <Link
              to="/about"
              style={{
                textDecoration: "none",
                color: "inherit",
                margin: "5px",
              }}
            >
              About
            </Link> */}
          </Box>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <Link
              to="/logout"
              style={{
                textDecoration: "none",
                color: "inherit",
                margin: "5px",
              }}
            >
              Logout
              <IconButton>
                <LogoutIcon />
              </IconButton>
            </Link>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Bar;

import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { Link } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AirIcon from '@mui/icons-material/Air';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';

const drawerWidth = 200;

export default function Sidebar() {
    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <Drawer
                variant="permanent"
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
                }}
            >
                <Toolbar />
                <Box sx={{ overflow: 'auto',paddingTop:"0rem" }}>
                    <List>
                        {['Home'].map((text, index) => (
                            <ListItem key={text} disablePadding>
                                <ListItemButton component={Link} to="/">
                                    <ListItemIcon>
                                        <AirIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={text} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                    <Divider />
                    {/* <List>
                        {['Performance Base'].map((text, index) => (
                            <ListItem key={text} disablePadding>
                                <ListItemButton component={Link} to="/perfbase">
                                <ListItemIcon>
                                        <AutoGraphIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={text} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List> */}
                    <Divider />
                </Box>
            </Drawer>

        </Box>
    );
}

import React, { useState } from 'react';
import { styled } from '@mui/system';
import { Grid, TextField, Button, Typography } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { ToggleButton, ToggleButtonGroup, IconButton } from '@mui/material';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import { Switch } from '@mui/material';

const FormContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  width: '50%', // Adjust the width as needed
  margin: 'auto',
});

const Form = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
});

const CorrBase = (props) => {
  const [alignment, setAlignment] = useState([]);
  // console.log('alignment:', alignment);


  const handleChange = (e) => {
    const { id, value } = e.target;
    switch (id) {
      case 'distanceToPollution':
        props.setDistanceToPollution(value);
        break;
      case 'distanceToCoast':
        props.setDistanceToCoast(value);
        break;
      case 'annualAveragedT':
        props.setAnnualAveragedT(value);
        break;
      case 'annualAveragedRH':
        props.setAnnualAveragedRH(value);
        break;
      default:
        break;
    }
    

  };


  const SmallLabel = styled('span')({
    fontSize: '0.85rem', // Adjust the font size as needed
    fontWeight: 'bold', // You can adjust other styles here as well
  });

  const handleTypeChange = (event, newAlignment) => {
    if (alignment === newAlignment) {
      setAlignment(null); // Toggle off if the same button is clicked again
    } else {
      setAlignment(newAlignment);
    }
    console.log(alignment)
  };

  const handleEvapChange = (event) => {
    props.setEvaporativeCooling(event.target.checked);
  };

  const handleClear = () => {
    props.setDistanceToPollution('');
    props.setDistanceToCoast('');
    props.setAnnualAveragedT('');
    props.setAnnualAveragedRH('');
    props.setEvaporativeCooling(false);
  };

  return (
    <div>
      {/* <Button style={{ padding: 0, float: "right" }} onClick={handleClear}>Clear</Button> */}
      <FormContainer style={{ paddingTop: '1rem', width: "80%" }}>
        {/* <Typography variant="h4">Corrosion Base</Typography> */}
        <Form>
          <Grid container spacing={4}>
            <Grid item xs={16} sm={6}>
              <TextField
                id="distanceToPollution"
                label={<SmallLabel>Distance from Industial zone/Harsh weather(in KM)</SmallLabel>}
                variant="outlined"
                fullWidth
                
                type="number"
                inputProps={{ min: 0 }}
                value={props.distanceToPollution}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={3} sm={0}>
              {/* <FormControlLabel
                
                control={
                  <Switch
                    checked={props.evaporativeCooling}
                    onChange={handleEvapChange}
                    name="evaporativeCoolingSwitch"
                    color="primary"
                  >
                  </Switch>
                }
                label={
                  <Typography variant="body1" color={props.evaporativeCooling ? 'primary' : 'textSecondary'}>
                    <span style={{ color: "black" }}>Evaporative Cooling</span>{props.evaporativeCooling ? ' (True)' : ' (False)'}
                  </Typography>
                }
              /> */}
            </Grid>
          </Grid>

          <ToggleButtonGroup
            color="primary"
            value={alignment}
            exclusive={false}
            size='small'
            onChange={handleTypeChange}
            aria-label="Platform"
          >
            <ToggleButton style={{ color: "#1976d2", padding: 0, paddingLeft: "10px" }} value="Advanced">{
              <SmallLabel>Advanced
                <IconButton
                  edge="end"
                  
                >
                  {alignment.length > 0 ? (
                    <KeyboardDoubleArrowUpIcon color="primary" />

                  ) : (
                    <KeyboardDoubleArrowDownIcon color="primary" />
                  )}
                </IconButton> </SmallLabel>}</ToggleButton>
          </ToggleButtonGroup>

          {alignment.length > 0 ? (
            <>
              <Grid container spacing={2}>
                <Grid item xs={16} sm={4}>
                  <TextField
                    id="distanceToCoast"
                    label={<SmallLabel>Distance to Coast(in KM)</SmallLabel>}
                    variant="outlined"
                    fullWidth
                    
                    type="number"
                    inputProps={{ min: 0 }}
                    value={props.distanceToCoast}
                    onChange={handleChange}
                    required
                  />
                </Grid>
                <Grid item xs={3} sm={4}>
                  <TextField
                    id="annualAveragedT"
                    label={<SmallLabel>Annual Averaged T</SmallLabel>}
                    variant="outlined"
                    fullWidth
                    
                    type="number"
                    inputProps={{ min: 0 }}
                    value={props.annualAveragedT}
                    onChange={handleChange}
                    required
                  />
                </Grid>
                <Grid item xs={3} sm={4}>
                  <TextField
                    id="annualAveragedRH"
                    label={<SmallLabel>Annual Averaged RH</SmallLabel>}
                    variant="outlined"
                    fullWidth
                    
                    type="number"
                    inputProps={{ min: 0 }}
                    value={props.annualAveragedRH}
                    onChange={handleChange}
                    required
                  />
                </Grid>
              </Grid>
            </>
          ) :
            <div></div>
          }
        </Form>
      </FormContainer>
    </div>
  );
};

export default CorrBase;

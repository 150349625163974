import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Cards from './Cards';
import SwipeableViews from 'react-swipeable-views';
import DataTable from './BuildingTable';
import PlotlyChart from './PlotlyChart';
import HistogramChart from './HistogramChart';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from "@mui/material/Backdrop";
import { alignProperty } from '@mui/material/styles/cssUtils';
import { Divider } from '@mui/material';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import {  LinearProgress } from '@mui/material';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
    };
}

const DividerWithText = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    margin: '1rem 0.5',
    '& .MuiDivider-root': {
        flexGrow: 1,
        borderBottomWidth: '1px',
    },
    '& .MuiTypography-root': {
        margin: '1 1rem',
        fontWeight: 'bold',
        fontSize: "larger"
    },
}));

const OutputPage = (props) => {
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(true);

    const centeredStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
    };

    React.useEffect(() => {
        if (props?.corrosionData?.surrogate_exist) {
            console.log("SUrrogate Exists...")
            // setOpen(false);
        } else {
            // setOpen(true);
        }

        // if (props.buidingInfoTableWeigh && props.buidingInfoTable.length > 1) {
        //     setLoading(false);
        // }
    }, [props]);

    console.log("outputpage Props", props)
    return (
        <div >
            <div style={{ marginTop: "8rem", marginBottom: "2rem" }}>
                <Backdrop
                    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                >
                    <CircularProgress />
                </Backdrop>
                {props.corrosionData && <Cards outputs={props.corrosionData}></Cards>}
            </div>

            <Divider style={{ marginTop: "2rem" }} />
            {/* <DividerWithText>
                <Divider />
                <Typography style={{ marginTop: "20px", marginBottom: "20px" }} variant="body1">Charts</Typography>
                <Divider />
            </DividerWithText> */}

            <div style={{  marginTop: "3rem", marginLeft: "15rem" }}>
                {props?.corrosionData?.surrogate_exist ? (
                    <>
                        <Box
                            sx={{
                                width: '100%',
                                bgcolor: 'background.paper',
                                borderColor: 'divider',
                                border: '1px solid black',
                                borderRadius: '8px',
                                overflow: 'hidden',
                            }}
                        >
                            <Box
                                sx={{
                                    borderColor: 'divider',
                                    borderRadius: '8px',
                                }}
                            >
                                <Tabs
                                    centered
                                    value={value}
                                    onChange={handleChange}
                                    indicatorColor="secondary"
                                    textColor="inherit"
                                    variant="fullWidth"
                                    aria-label="scrollable auto tabs example"
                                    sx={{
                                        color: "black",
                                        display: 'flex',
                                        alignItems: 'center',
                                        borderBottom: '1px solid black',
                                        borderRadius: '8px',
                                        backgroundColor: "#cde7ff",
                                        '& .MuiTabs-indicator': {
                                            backgroundColor: 'transparent',
                                            color: 'black',
                                        },
                                        '& .MuiTab-root': {
                                            borderRight: '1px solid black',
                                            color: 'black',
                                            '&.Mui-selected': {
                                                backgroundColor: '#1574d391',
                                                // borderRadius: '8px',
                                            },
                                        },
                                        '& .MuiTab-root:last-of-type': {
                                            borderRight: 'none', // Remove the border from the last tab
                                            color: 'black',
                                        },
                                    }}
                                >
                                    <Tab
                                        label="Cooling Load Profile (kW)"
                                        {...a11yProps(0)}
                                        sx={{
                                            height: '4rem',
                                            color: 'black',
                                            fontSize: '1.2rem',
                                            fontWeight: 'bold',
                                        }}
                                    />
                                    <Tab
                                        label="Energy Consumption Chart "
                                        {...a11yProps(1)}
                                        sx={{
                                            height: '4rem',
                                            color: 'black',
                                            fontSize: '1.2rem',
                                            fontWeight: 'bold',
                                        }}
                                    />
                                    <Tab
                                        label="COP Values Chart"
                                        {...a11yProps(2)}
                                        sx={{
                                            height: '4rem',
                                            color: 'black',
                                            fontSize: '1.2rem',
                                            fontWeight: 'bold',
                                        }}
                                    />
                                </Tabs>
                            </Box>
                            <SwipeableViews
                                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                index={value}
                                onChangeIndex={handleChangeIndex}
                                style={{ marginBottom: "2rem" }}
                            >
                                <TabPanel value={value} index={0} dir={theme.direction}>
                                    <div style={centeredStyle}>
                                        <h2 style={{ marginTop: "2rem" }}>Cooling Load Profile (kW)</h2>
                                        <HistogramChart
                                            months={props.corrosionData.graph_data.months}
                                            actLoad={props.corrosionData.graph_data.actLoad}
                                        />
                                        <p>Energy consumption peaks during the <span style={{ color: "#f99a45" }}>Summer</span></p>
                                    </div>
                                </TabPanel>
                                <TabPanel value={value} index={1} dir={theme.direction}>
                                    <div style={centeredStyle}>
                                        <h2>Energy Consumption Chart</h2>
                                        <PlotlyChart
                                            configType="sum"
                                            months={props.corrosionData.graph_data.months}
                                            energyPost={props.corrosionData.graph_data.energyPost}
                                            energyPre={props.corrosionData.graph_data.energyPre}
                                        />
                                    </div>
                                </TabPanel>
                                <TabPanel value={value} index={2} dir={theme.direction}>
                                    <div style={centeredStyle}>
                                        <h2>COP Values Chart</h2>
                                        <PlotlyChart
                                            configType="average"
                                            months={props.corrosionData.graph_data.months}
                                            copPost={props.corrosionData.graph_data.copPost}
                                            copPre={props.corrosionData.graph_data.copPre}
                                        />
                                    </div>
                                </TabPanel>
                            </SwipeableViews>
                        </Box>
                    </>
                ) : (
                    <Box
                        sx={{
                            padding: '30px',
                            textAlign: 'center',
                            backgroundColor: '#1976d2',
                            color: '#007bb2',
                            borderRadius: '8px',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                            maxWidth: '800px',
                            margin: 'auto',
                        }}
                    >
                        <HourglassBottomIcon sx={{ fontSize: '40px', color: 'white' }} />
                        <Typography variant="h5" sx={{ marginTop: '10px', color: 'white', fontWeight: 'bold' }}>
                            Report Generation in Progress
                        </Typography>
                        <Typography variant="body1" sx={{ marginTop: '10px',color: 'white'}}>
                            Please wait while we generate your report.
                        </Typography>
                        {/* <Box sx={{ marginTop: '20px' }}>
                            <CircularProgress sx={{color:"white"}} size={60} />
                        </Box> */}
                        <Box sx={{ marginTop: '20px' }  }>
                            <LinearProgress sx={{ height: '8px', borderRadius: '4px' }} />
                        </Box>
                    </Box>
                )}
            </div>
        </div>
    );
};

export default OutputPage;

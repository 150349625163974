import React from 'react';
import Plot from 'react-plotly.js';

const HistogramChart = ({ months, actLoad }) => {
    // Prepare data for Plotly histogram
    const data = [{
        x: months,
        y: actLoad,
        type: 'bar',
        mode: 'lines+markers+text',
        textposition: 'bottom center',
        marker: {
            color: months.map(month => 
                ['May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'].includes(month) ? '#f99a45' : '#489cf1'
            )
        },
    }];
    

    const layout = {
        title: 'Cooling Load Profile (KW)',
        xaxis: {
            title: 'Month',
        },
        yaxis: {
            title: 'Average Load (KW)',
        },
    };

    return (
        <Plot
            data={data}
            layout={layout}
            style={{ width: '100%', height: '450px' }}
        />
    );
};

export default HistogramChart;

import axios from "axios";


// const PROD_URL = "https://n0lwh6v7b3.execute-api.us-east-1.amazonaws.com/prod";
// const DEV_URL = "https://zbzz4zofyk.execute-api.ap-south-1.amazonaws.com/dev";
// // const API_BASE_URL = "http://localhost:5000/";

const API_BASE_URL = process.env.REACT_APP_BASE_API_ENDPOINT;

export const API = {
  getData: async (url, params, responsetype) => {
    try {
      const response = await axios.get(`${API_BASE_URL}${url}`, {
        params: params,
        responseType: responsetype,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("msal.idtoken")}`,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  postData: async (url, data) => {
    try {
      const response = await axios.post(`${API_BASE_URL}${url}`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("msal.idtoken")}`,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  postDataZip: async (url, data) => {
    try {
      const response = await axios.post(`${API_BASE_URL}${url}`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("msal.idtoken")}`,
        },
        responseType: "arraybuffer",
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  postFile: async (url, data) => {
    try {
      const response = await axios.post(`${API_BASE_URL}${url}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("msal.idtoken")}`,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

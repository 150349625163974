import * as React from 'react';
import { API } from '../MyApi';
import Card from '@mui/joy/Card';
import Typography from '@mui/joy/Typography';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/joy/CardContent';
import EnergySavingsLeafIcon from '@mui/icons-material/EnergySavingsLeaf';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Co2Icon from '@mui/icons-material/Co2';
import DomainVerificationIcon from '@mui/icons-material/DomainVerification';
import InsightsIcon from '@mui/icons-material/Insights';
import Button from '@mui/material/Button';
import { CircularProgress, Divider } from '@mui/material';
import Box from '@mui/material/Box';

const cardDetails = [
    // { key: 'annual_energy_consumption_post_service_kWh', label: 'Annual Energy Consumption Post Service (kWh)', icon: <AcUnitTwoToneIcon style={{ height: "3.5rem", width: "3.5rem" }} /> },
    // { key: 'annual_energy_consumption_pre_service_kWh', label: 'Annual Energy Consumption Pre Service (kWh)', icon: <TaskIcon style={{ height: "3.5rem", width: "3.5rem" }} /> },
    // { key: 'annual_energy_savings_prcnt', label: 'Annual Energy Saving (%)', icon: <TaskIcon style={{ height: "3.5rem", width: "3.5rem" }} /> },
    // { key: 'avg_cop_value_pre_service', label: 'Average COP Value Pre Service', icon: <InsightsIcon style={{ height: "3.5rem", width: "3.5rem" }} /> },
    // { key: 'avg_cop_value_post_service', label: 'Average COP Value Post Service', icon: <InsightsIcon style={{ height: "3.5rem", width: "3.5rem" }} /> },
    { key: 'annual_energy_saving_kWh', addon: "(kWh)", label: 'Annual Energy Saving (kWh)', icon: <EnergySavingsLeafIcon style={{ height: "3.5rem", width: "3.5rem" }} /> },
    { key: 'annual_cost_saving', addon: "", label: 'Annual Cost Saving', icon: <MonetizationOnIcon style={{ height: "3.5rem", width: "3.5rem" }} /> },
    { key: 'efficiency_improvement_prcnt', addon: "%", label: 'Efficiency Improvement (%)', icon: <InsightsIcon style={{ height: "3.5rem", width: "3.5rem" }} /> },
    { key: 'annual_roi_prcnt', addon: "%", label: 'Annual ROI (%)', icon: <DomainVerificationIcon style={{ height: "3.5rem", width: "3.5rem" }} /> },
    { key: 'payback_period_yrs', addon: "Years", label: 'Payback Period (Years)', icon: <AccessTimeIcon style={{ height: "3.5rem", width: "3.5rem" }} /> },
    { key: 'annual_CO2_savings_tCO2eq', addon: "(tCO₂eq)", label: 'Annual CO2e Reduction', icon: <Co2Icon style={{ height: "3.5rem", width: "3.5rem" }} /> },
];

export default function Cards(props) {
    const handledownloadreport = async (path) => {
        try {
            const param = {
                path: path
            };
            const pre_sign_link = await API.postData("/download_file", param);
            if (pre_sign_link) {
                window.location.assign(pre_sign_link);
            }
        } catch (error) {
            console.error("Error downloading the report:", error);
        }
    };
    const isDisabled = !props?.outputs?.surrogate_exist;
    console.log("props.outputs in Cards page", props.outputs);

    return (
        <div>
            <Button
                disabled={isDisabled}
                style={{
                    width: '50%',
                    height: '100%',
                    fontSize: 'medium',
                    borderRadius: "12px",
                    backgroundColor: isDisabled ? '#8bc34a' : '#0f8427',
                    color: 'white',
                    paddingTop: "15px",
                    paddingBottom: "15px",
                    boxShadow: "rgb(106 156 218 / 71%) 5px 4px 10px 0px",
                    marginLeft: "30rem",
                    marginBottom: "1rem"
                }}
                onClick={() => handledownloadreport(props?.outputs?.reportpath)}
            >
                {isDisabled ? (
                    <Box display="flex" alignItems="center">
                        <CircularProgress size={24} color="inherit" style={{ marginRight: '8px' }} />
                        {props?.outputs?.sitedata}    ---   <span style={{ fontSize: "large" }}>Download Report</span>
                    </Box>
                ) : (
                    <span>{props?.outputs?.sitedata}    ---   <span style={{ fontSize: "large" }}>Download Report</span></span>
                )}
            </Button>
            <Divider></Divider>
            <Divider></Divider>
            <Grid container rowSpacing={3} spacing={3} sx={{
                justifyContent: 'center',
                '@media screen and (max-width: 100%)': {
                    // Define styles for default zoom level (100%)
                },
                '@media screen and (max-width: 75%)': {
                    // Define styles for 75% zoom level
                    '& .cardContainer': {
                        width: '12rem',
                    },
                },
                '@media screen and (max-width: 50%)': {
                    // Define styles for 50% zoom level
                    '& .cardContainer': {
                        width: '10rem',
                    },
                },
                marginTop: "1rem",
                marginLeft: "11rem",
                width: "85%",
            }}>


                {cardDetails.map((card, index) => (
                    <Grid item key={index} xs={12} sm={6} md={3}>
                        <Card className="cardContainer" style={{ boxShadow: "rgb(106 156 218 / 71%) 5px 4px 10px 0px" }} variant="solid" color="primary" invertedColors>
                            <CardContent orientation="horizontal">
                                {card.icon}
                                <CardContent>
                                    <Typography level="h6">{card.label}</Typography>
                                    <Typography style={{ paddingTop: "1.0rem" }} level="h3">
                                    {props?.outputs?.[card.key] !== undefined ? (props?.outputs?.[card.key] !== 0 ? `${props?.outputs?.[card.key]} ${card.addon}` : "-") : ""}
                                    </Typography>
                                </CardContent>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}

                {/* <Grid item xs={12} sm={6} md={3}>
                    <Card className="cardContainer" style={{ boxShadow: "rgb(106 156 218 / 71%) 5px 4px 10px 0px" }} variant="solid" color="primary" invertedColors>
                        <CardContent orientation="horizontal">
                            <CardContent>
                                
                            </CardContent>
                        </CardContent>
                    </Card>
                </Grid> */}

            </Grid>
        </div>
    );
}

// PlotlyChart.js
import React from 'react';
import Plot from 'react-plotly.js';

const PlotlyChart = ({ configType, months, energyPost, energyPre, copPost, copPre }) => {
  let data, layout;

  if (configType === 'sum') {
    data = [
      {
        x: months,
        y: energyPost,
        type: 'scatter',
        mode: 'lines+markers+text',
        name: 'Post service kWh',
        text: energyPost,
        textposition: 'bottom center',
      },
      {
        x: months,
        y: energyPre,
        type: 'scatter',
        mode: 'lines+markers+text',
        name: 'Pre service kWh',
        text: energyPre,
        textposition: 'top center',
      },
    ];

    layout = {
      title: 'Monthly Energy Consumption Summary Profile(kWh)',
      xaxis: { title: 'Month' },
      yaxis: { title: 'kWh' },
    };
  } else if (configType === 'average') {
    const maxLimit = Math.max(...copPost) + 0.15;
    const minLimit = Math.min(...copPre) - 0.1;

    data = [
      {
        x: months,
        y: copPost,
        type: 'scatter',
        mode: 'lines+markers+text',
        name: 'Post service COP',
        text: copPost,
        textposition: 'top center',
      },
      {
        x: months,
        y: copPre,
        type: 'scatter',
        mode: 'lines+markers+text',
        name: 'Pre service COP',
        text: copPre,
        textposition: 'top center',
      },
    ];

    layout = {
      title: 'Monthly Average Efficiency COP Profile(W/W)',
      xaxis: { title: 'Month' },
      yaxis: { title: 'COP', range: [minLimit, maxLimit] },
    };
  }

  return <Plot data={data} layout={layout} style={{ width: '100%', height: '600px' }} />;
};

export default PlotlyChart;
